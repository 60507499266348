import { HistoryLocation } from '@reach/router'
import React, { ReactElement } from 'react'

import PageModule from './PageModule'

export type PageModuleArrayProps = SOG.Props.IDefault & {
  location: HistoryLocation
  modulearray: Array<SOG.Contentful.ModuleUnion>
}

export default function PageModuleArray({
  className,
  location,
  modulearray,
}: PageModuleArrayProps): ReactElement {
  return (
    <>
      {modulearray.map((mod, i) => (
        <PageModule
          key={`${mod.id}${i}`}
          className={className}
          location={location}
          pagemodule={mod}
        />
      ))}
    </>
  )
}
