import React, { ReactElement } from 'react'

import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import themeConfig from '@config/theme'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import { Link } from 'gatsby'
import Image from '@components/core/media/image'
import { timestampToLocaleDate } from '@utils/string-tools'

export type NewsCardProps = SOG.Props.IDefault & {
  linkTo: SOG.Props.LinkTo
  image: SOG.Props.Image
  topic: string
  type?: 'articleDetail' | 'default'
  headline: string
  subline?: string
  releaseDate: string
  theme?: SOG.Contentful.Theme
}

export type TopicDateProps = SOG.Props.IDefault & {
  topic: string
  releaseDate: string
  theme?: SOG.Contentful.Theme
  type: 'articleDetail' | 'default'
}

function TopicDate({ topic, releaseDate, sx, type }: TopicDateProps) {
  return (
    <Box
      sx={[
        (sxTheme) => ({
          [sxTheme.breakpoints.down('md')]: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: '#FFF',
            pr: 4,
            pt: 3,
          },
        }),
        sx as SOG.Props.SxInArray,
      ]}
    >
      <Typography
        variant={type === 'default' ? 'baseBold' : 'smallBold'}
        sx={(muiTheme: Theme) => ({
          mr: 4,
          color: themeConfig.palette.grey[500],
          textTransform: 'uppercase',
          verticalAlign: 'bottom',
          [muiTheme.breakpoints.up('md')]: {
            verticalAlign: type === 'default' ? 'top' : 'inherit',
          },
        })}
      >
        {topic}
      </Typography>
      <Typography
        variant={type === 'default' ? 'base' : 'small'}
        component="span"
        sx={(muiTheme: Theme) => ({
          verticalAlign: 'bottom',
          [muiTheme.breakpoints.up('md')]: {
            verticalAlign: type === 'default' ? 'top' : 'inherit',
          },
        })}
      >
        {timestampToLocaleDate(releaseDate)}
      </Typography>
    </Box>
  )
}

export default function NewsCard({
  linkTo,
  image,
  topic,
  type = 'default',
  headline,
  subline,
  releaseDate,
  theme,
}: NewsCardProps): ReactElement {
  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('md')
  )

  return (
    <Box
      component={Link}
      to={linkTo.url}
      sx={(muiTheme: Theme) => ({
        textDecoration: 'none',
        [muiTheme.breakpoints.up('md')]: type === 'default' && {
          display: 'flex',
        },
      })}
    >
      <Box
        sx={(muiTheme: Theme) => ({
          position: 'relative',
          mb: 4,
          [muiTheme.breakpoints.up('md')]: {
            mb: 6,
          },
        })}
      >
        <Image
          {...image}
          sx={(muiTheme: Theme) => ({
            aspectRatio: '5/3',
            height: '100%',
            objectFit: 'cover',
            [muiTheme.breakpoints.up('md')]: type === 'default' && {
              width: '468px',
              mr: 8,
            },
          })}
        />
        {isMobile && (
          <TopicDate
            topic={topic}
            releaseDate={releaseDate}
            type={type}
            theme={theme}
          />
        )}
      </Box>
      <Box>
        {!isMobile && (
          <TopicDate
            topic={topic}
            releaseDate={releaseDate}
            type={type}
            theme={theme}
            sx={{ mb: 4 }}
          />
        )}
        <Headline variant={type === 'default' ? 'h5' : 'h7'} component="div">
          {headline}
        </Headline>
        {type === 'default' && (
          <Copy
            variant="body1"
            sx={(muiTheme: Theme) => ({
              mt: 4,
              [muiTheme.breakpoints.up('md')]: {
                mt: 6,
              },
            })}
          >
            {subline}
          </Copy>
        )}
      </Box>
    </Box>
  )
}
