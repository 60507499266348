import React, {
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, Theme, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { AnimatePresence, motion } from 'framer-motion'

import {
  getAssetProp,
  getAssetPropList,
  getGlobalProp,
  getGlobalPropList,
  isVideoFile,
} from '@utils/props'
import PageModuleArray from '@components/system/PageModuleArray'
import NewsCard from '@components/core/ui/newsCard'
import Filter from '@components/core/ui/filter'
import { pageListMotionProps } from '@utils/animation'
import CollapsableContainer from '@components/core/container/collapsableContainer'
import useGlobalState from '@components/system/hooks/useGlobalState'
import MediaCard from '@components/core/ui/mediaCard'
import useContentfulGlobalMedia from '@components/system/hooks/contentful/useContentfulGlobalMedia'
import useLightbox from '@components/system/hooks/useLightbox'
import Button from '@components/core/ui/button'
import Pagination from '@components/core/ui/pagination'

type ResultsConfigType = {
  entriesGlobalMedia?: SOG.Props.IGlobalMedia[]
  entriesPageArticleDetail?: SOG.Props.IPageArticleDetail[]
  entriesPerPage: number
  totalPages: number
  filtersTotal: number
  filters: {
    topics: SOG.Props.IGlobalTopic[]
    brands: SOG.Props.IGlobalBrand[]
    mediums: SOG.Props.IGlobalMedium[]
    targetGroups: SOG.Props.IGlobalTargetGroup[]
    formats: string[]
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeDuplicates = (entries: any) =>
  entries
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((entry: any) => !!entry)
    .filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ id }: any, index: number, array: any[]) =>
        array.findIndex((entry) => entry.id === id) === index
    )

export default function PageGenericOverview({
  data,
  location,
}: SOG.Contentful.IPageGenericOverview): ReactElement {
  data.allContentfulEntry.nodes = data.allContentfulEntry.nodes.filter(
    (node) => !node.metadata?.tags.find((tag) => tag?.name?.includes('inside'))
  )
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { globalFilters, setGlobaleFilters, setGlobalFilterResultsTotal } =
    useGlobalState()
  const { showDialog } = useLightbox()
  const listRef = useRef<HTMLElement>()
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const { entryType, modules } = data.contentfulPageGenericOverview

  const results = useMemo(() => {
    const results: ResultsConfigType = {
      entriesPerPage: 5,
      totalPages: 0,
      filtersTotal: 0,
      filters: {
        topics: [],
        brands: [],
        mediums: [],
        targetGroups: [],
        formats: [],
      },
    }

    switch (entryType) {
      case 'ContentfulPageArticleDetail':
        results.entriesPageArticleDetail = (
          data.allContentfulEntry.nodes as SOG.Contentful.IPageArticleDetail[]
        ).map(
          ({
            topic,
            medium,
            image,
            teaserImage,
            brand,
            contacts,
            downloads,
            fields,
            ...rest
          }) => ({
            topic: getGlobalProp(topic) as SOG.Props.IGlobalTopic,
            medium: getGlobalProp(medium) as SOG.Props.IGlobalMedium,
            teaserImage: getAssetProp(teaserImage) as SOG.Props.Image,
            image: getAssetProp(image) as SOG.Props.Image,
            contacts: getGlobalPropList(contacts) as SOG.Props.IGlobalContact[],
            brand: getGlobalProp(brand) as SOG.Props.IGlobalBrand,
            downloads: getAssetPropList(downloads) as SOG.Props.Asset[],
            linkTo: fields?.linkTo,
            ...rest,
          })
        ) as SOG.Props.IPageArticleDetail[]
        results.totalPages = Math.ceil(
          results.entriesPageArticleDetail.length / results.entriesPerPage
        )

        results.entriesPageArticleDetail.forEach((entry) => {
          results.filters.topics.push(entry.topic)
          results.filters.mediums.push(entry.medium)
          results.filters.brands.push(entry.brand)
        })

        break
      case 'ContentfulGlobalMedia':
        results.entriesGlobalMedia = (
          data.allContentfulEntry.nodes as SOG.Contentful.IGlobalMedia[]
        ).map((entry) => getGlobalProp(entry) as SOG.Props.IGlobalMedia)
        results.totalPages = Math.ceil(
          results.entriesGlobalMedia.length / results.entriesPerPage
        )
        results.entriesGlobalMedia.forEach((entry) => {
          results.filters.topics.push(...entry.topics)
          results.filters.mediums.push(...entry.mediums)
          results.filters.brands.push(...entry.brands)
          results.filters.targetGroups.push(...entry.targets)
          results.filters.formats.push(
            entry.media?.file?.contentType.toUpperCase()
          )
        })
        break
      default:
        break
    }

    results.filters.topics = removeDuplicates(results.filters.topics)
    results.filters.brands = removeDuplicates(results.filters.brands)
    results.filters.mediums = removeDuplicates(results.filters.mediums)
    results.filters.targetGroups = removeDuplicates(
      results.filters.targetGroups
    )
    results.filters.formats = results.filters.formats.filter(
      (format, index, array) =>
        array.findIndex((entry) => entry === format) === index
    )
    return results
  }, [data.allContentfulEntry, entryType])

  const {
    entriesGlobalMedia,
    entriesPageArticleDetail,
    totalPages,
    entriesPerPage,
    filters,
    filtersTotal,
  }: ResultsConfigType = useMemo(() => {
    const filtersTotal =
      globalFilters.topics.length +
      globalFilters.brands.length +
      globalFilters.mediums.length +
      globalFilters.targetGroups.length +
      globalFilters.formats.length

    const sortedEntries = results.entriesPageArticleDetail?.sort((a, b) =>
      new Date(a.releaseDate).getTime() < new Date(b.releaseDate).getTime()
        ? 1
        : -1
    )

    switch (entryType) {
      case 'ContentfulPageArticleDetail':
        const entriesPageArticleDetail =
          (!!sortedEntries &&
            sortedEntries.filter((entry) => {
              const hitTopic =
                !entry.topic ||
                !globalFilters.topics.length ||
                globalFilters.topics.some(
                  (filterEntry) => entry.topic.id === filterEntry.id
                )
              const hitBrand =
                !entry.brand ||
                !globalFilters.brands.length ||
                globalFilters.brands.some(
                  (filterEntry) => entry.brand.id === filterEntry.id
                )
              const hitMedium =
                !entry.medium ||
                !globalFilters.mediums.length ||
                globalFilters.mediums.some(
                  (filterEntry) => entry.medium.id === filterEntry.id
                )
              return hitTopic && hitBrand && hitMedium
            })) ||
          []
        return {
          ...results,
          entriesPageArticleDetail,
          totalPages: Math.ceil(
            entriesPageArticleDetail.length / results.entriesPerPage
          ),
          filtersTotal,
        }
      case 'ContentfulGlobalMedia':
        const entriesGlobalMedia =
          results.entriesGlobalMedia?.filter((entry) => {
            const hitTopic =
              !entry.topics ||
              !globalFilters.topics.length ||
              globalFilters.topics.some((filterEntry) =>
                entry.topics.some((topic) => topic.id === filterEntry.id)
              )
            const hitBrand =
              !entry.brands ||
              !globalFilters.brands.length ||
              globalFilters.brands.some((filterEntry) =>
                entry.brands.some((brand) => brand.id === filterEntry.id)
              )
            const hitMedium =
              !entry.mediums ||
              !globalFilters.mediums.length ||
              globalFilters.mediums.some((filterEntry) =>
                entry.mediums.some((medium) => medium.id === filterEntry.id)
              )
            const hitTargetGroup =
              !entry.targets ||
              !globalFilters.targetGroups.length ||
              globalFilters.targetGroups.some((filterEntry) =>
                entry.targets.some(
                  (targetGroup) => targetGroup.id === filterEntry.id
                )
              )

            const hitFormat =
              !globalFilters.formats.length ||
              globalFilters.formats.includes(
                entry.media?.file?.contentType.toUpperCase()
              )
            return (
              hitTopic && hitBrand && hitMedium && hitTargetGroup && hitFormat
            )
          }) || []
        return {
          ...results,
          entriesGlobalMedia,
          totalPages: Math.ceil(
            entriesGlobalMedia.length / results.entriesPerPage
          ),
          filtersTotal,
        }
      default:
        return results
    }
  }, [results, globalFilters])

  const lightboxEntries = useContentfulGlobalMedia(entriesGlobalMedia || [])

  useEffect(() => {
    setCurrentPageIndex(0)
  }, [globalFilters])

  useEffect(() => {
    setGlobalFilterResultsTotal(
      entriesGlobalMedia?.length || entriesPageArticleDetail?.length || 0
    )
  }, [entriesGlobalMedia, entriesPageArticleDetail])

  const pageChangeHandler = (value: number) => {
    setCurrentPageIndex(value - 1)
    if (listRef.current) {
      window.scroll({
        top: listRef.current.offsetTop - 130,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const renderArticleDetail = (
    props: SOG.Props.IPageArticleDetail,
    index: number
  ) => {
    return (
      <Grid
        item
        xs={12}
        key={props.id}
        component={motion.div}
        {...pageListMotionProps}
      >
        <NewsCard
          linkTo={props.linkTo}
          image={props.image}
          topic={props.topic?.title}
          headline={props.headline}
          subline={props.subline}
          releaseDate={props.releaseDate}
          key={index}
        />
      </Grid>
    )
  }

  const renderMedia = (props: SOG.Props.IGlobalMedia, index: number) => {
    const isVideo = isVideoFile(props.media?.file)
    if (isVideo && !props.thumbnail) return null

    return (
      <Grid
        item
        xs={12}
        md={4}
        key={props.id}
        component={motion.div}
        {...pageListMotionProps}
      >
        <MediaCard
          title={props.title}
          description={props.description}
          image={
            props.thumbnail ? props.thumbnail : (props.media as SOG.Props.Image)
          }
          isVideo={isVideoFile(props.media?.file)}
          key={index}
          onClick={() => {
            showDialog({
              type: 'media',
              id: props.anchor,
              props: {
                initialIndex: index,
                entries: lightboxEntries,
              },
            })
          }}
        />
      </Grid>
    )
  }

  return (
    <>
      {!!modules && (
        <PageModuleArray modulearray={modules} location={location} />
      )}
      <Container maxWidth="lg">
        {isMobile && (
          <Button
            variant="secondary"
            icon="ChevronRight"
            onClick={() =>
              showDialog({
                id: 'filter',
                type: 'filter',
                props: filters,
              })
            }
            sx={{ width: '100%', mb: 12 }}
          >
            <FormattedMessage
              id={
                filtersTotal > 0 ? 'button.filterNews' : 'button.filterNewsNone'
              }
              values={{
                total: filtersTotal,
              }}
            />
          </Button>
        )}
        {!isMobile && (
          <Filter
            topics={filters.topics}
            initialTopics={globalFilters.topics}
            brands={filters.brands}
            initialBrands={globalFilters.brands}
            mediums={filters.mediums}
            initialMediums={globalFilters.mediums}
            targetGroups={filters.targetGroups}
            initialTargetGroups={globalFilters.targetGroups}
            formats={filters.formats}
            initialFormats={globalFilters.formats}
            sx={{ mb: 16 }}
            onChange={(values) => {
              setGlobaleFilters(values)
            }}
          />
        )}
        <AnimatePresence initial={false}>
          <CollapsableContainer isOpen>
            <Grid
              ref={listRef as RefObject<HTMLDivElement>}
              container
              spacing={12}
              sx={(theme) => ({
                mb: 10,

                [theme.breakpoints.up('lg')]: {
                  mb: 20,
                },
              })}
            >
              {entriesPageArticleDetail &&
                entriesPageArticleDetail
                  .slice(
                    currentPageIndex * entriesPerPage,
                    currentPageIndex * entriesPerPage + entriesPerPage
                  )
                  .map(renderArticleDetail)}
              {entriesGlobalMedia &&
                entriesGlobalMedia
                  .slice(
                    currentPageIndex * entriesPerPage,
                    currentPageIndex * entriesPerPage + entriesPerPage
                  )
                  .map(renderMedia)}
            </Grid>
          </CollapsableContainer>
        </AnimatePresence>
        {totalPages > 1 && (
          <Pagination count={totalPages} onChange={pageChangeHandler} />
        )}
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query PageGenericOverviewByPath(
    $id: String!
    $locale: String!
    $filterEntryType: String
  ) {
    contentfulPageGenericOverview(
      id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      entryType
      modules {
        ...ContentfulModulesFragment
      }
    }
    allContentfulEntry(
      filter: {
        node_locale: { eq: $locale }
        internal: { type: { eq: $filterEntryType } }
      }
    ) {
      nodes {
        ...PageArticleDetailFragment
        ... on ContentfulGlobalMedia {
          __typename
          contentful_id
          id
          node_locale
          anchor
          title
          copyright
          description {
            description
          }
          media {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 600
              height: 600
              placeholder: BLURRED
            )
            file {
              url
              fileName
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 600
              height: 600
              placeholder: BLURRED
            )
            file {
              url
              fileName
              contentType
            }
          }
          topics {
            ...GlobalTopicFragment
          }
          mediums {
            ...GlobalMediumFragment
          }
          targets {
            ...GlobalTargetGroupFragment
          }
          brands {
            ...GlobalBrandFragment
          }
        }
      }
    }
  }
`
