import React, { ReactElement, useState, useEffect } from 'react'
import { Box, PaginationItem, Theme, useMediaQuery } from '@mui/material'
import usePagination from '@mui/material/usePagination/usePagination'
import { FormattedMessage } from 'react-intl'

import Headline from '@components/core/text/headline'

export type PaginationProps = SOG.Props.IDefault & {
  count: number
  onChange: (page: number) => void
}

export default function Pagination({
  count,
  onChange,
  sx,
}: PaginationProps): ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [currentPage, setCurrentPage] = useState(1)
  const { items } = usePagination({
    count,
    siblingCount: 0,
  })

  useEffect(() => {
    const selectedItem = items.find((entry) => entry.selected)
    if (selectedItem && selectedItem.page !== currentPage) {
      const page = selectedItem.page || 1
      onChange(page)
      setCurrentPage(page)
    }
  }, [items])

  return (
    <Box component="nav" sx={sx}>
      <Box
        component="ul"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          listStyleType: 'none',
          paddingLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          '& .MuiPaginationItem-ellipsis': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        {items.map(({ page, type, selected, ...item }, index) => {
          if (isMobile && type !== 'previous' && type !== 'next') {
            return null
          }

          if (isMobile && type === 'previous') {
            return (
              <>
                <li key={index}>
                  <PaginationItem
                    type={type}
                    shape="rounded"
                    {...item}
                    size={isMobile ? 'small' : 'large'}
                  />
                </li>
                <li>
                  <Headline variant="h7" sx={{ px: 8 }}>
                    <FormattedMessage
                      id="pagination.fromTo"
                      values={{ from: currentPage, to: count }}
                    />
                  </Headline>
                </li>
              </>
            )
          }

          return type === 'previous' || type === 'next' ? (
            <li key={index}>
              <PaginationItem
                type={type}
                shape="rounded"
                {...item}
                size={isMobile ? 'small' : 'large'}
              />
            </li>
          ) : (
            <li key={index}>
              <PaginationItem
                page={page}
                type={type}
                selected={selected}
                shape="rounded"
                size={isMobile ? 'small' : 'large'}
                {...item}
              />
            </li>
          )
        })}
      </Box>
    </Box>
  )
}
