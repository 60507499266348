import React, { ReactElement } from 'react'

import { Box } from '@mui/material'
import Headline from '@components/core/text/headline'
import Image from '@components/core/media/image'
import Icon from '../icon'

export type MediaCardProps = SOG.Props.IDefault & {
  title: string
  description: string
  image: SOG.Props.Image
  isVideo?: boolean
  onClick: () => void
}

export default function MediaCard({
  title,
  description,
  image,
  onClick,
  isVideo,
  sx,
}: MediaCardProps): ReactElement {
  return (
    <Box
      component="div"
      role="button"
      onClick={onClick}
      sx={[
        sx as SOG.Props.SxInArray,
        (theme) => ({
          transition: 'color 500ms ease',
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.text.secondary,
          },
        }),
      ]}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          mb: 4,
          [theme.breakpoints.up('md')]: {
            mb: 6,
          },
          overflow: 'hidden',
        })}
      >
        {isVideo && (
          <Icon
            name="Play"
            fontSize="inherit"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '80px',
              color: 'text.inverted',
              zIndex: 1,
            }}
          />
        )}
        <Image
          {...image}
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      <Headline variant="h7" component="div">
        {title} - {description}
      </Headline>
    </Box>
  )
}
